<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :labelWidth="labelWidth"
      :buttonShow="false"
    >
      <div slot="toolSub">
        <el-button type="primary" icon="el-icon-upload2" @click="exportExcel"
          >导出</el-button
        >
      </div>
      <div class="search" slot="tool">
        <el-input
          clearable
          placeholder="请输入商户姓名"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">商户姓名</template>
        </el-input>
        <el-input
          clearable
          placeholder="请输入摊位编号"
          v-model="searchQuery.stallNo"
          class="searchInput"
        >
          <template slot="prepend">摊位编号</template>
        </el-input>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">开始时间</div>
          <el-date-picker
            style="width: 100%"
            clearable
            value-format="yyyy-MM-dd"
            v-model="searchQuery.startDate"
            type="date"
            placeholder="选择开始时间"
          >
          </el-date-picker>
        </div>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">结束时间</div>
          <el-date-picker
            style="width: 100%"
            clearable
            value-format="yyyy-MM-dd"
            v-model="searchQuery.endDate"
            type="date"
            placeholder="选择结束时间"
          >
          </el-date-picker>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/statistics/commodityTransactionList",
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [4, 4, 4, 4],
        thirdSoltSpan: [20, 20, 20, 20],
      },
      searchVal: "",
      jobList: [],
      searchQuery: { name: "", stallNo: "", startDate: "", endDate: "" },
      labelWidth: "120px",
      props: [
        {
          label: "订单号",
          prop: "id",
          align: "center",
          width: 200,
        },
        {
          label: "商户名称",
          prop: "merchantPersonnelName",
          align: "center",
        },
        {
          label: "摊位编号",
          prop: "marketStallNo",
          align: "center",
        },

        {
          label: "商品名称",
          prop: "marketCommodityName",
          align: "center",
        },
        // {
        //   label: "商品单位id",
        //   prop: "marketCommodityUnitId",
        //   align: "center",
        // },
        // {
        //   label: "商品单位名称",
        //   prop: "marketCommodityUnitName",
        //   align: "center",
        // },
        {
          label: "商品单价(元/斤)",
          prop: "marketCommodityUnitPrice",
          align: "center",
        },
        {
          label: "商品重量(斤)",
          prop: "marketCommodityWeight",
          align: "center",
        },
        {
          label: "交易金额(元)",
          prop: "transactionAmount",
          align: "center",
        },
        {
          label: "日期",
          prop: "createTime",
          align: "center",
        },
        {
          label: "传输类型",
          prop: "dataType",
          align: "center",
          formatter: function (row) {
            const statusMap = { 0: "上称传输", 1: "按键传输" };
            return statusMap[row.dataType];
          },
        },
        {
          label: "支付状态",
          prop: "payStatus",
          align: "center",
          type: "formatterColor",
          formatter: function (row) {
            const statusMap = { 0: "未支付", 1: "已支付", 2: "支付金额有误" };
            return statusMap[row.payStatus];
          },
        },
      ],
      formProps: [],
    };
  },
  created() {},
  methods: {
    async exportExcel() {
      const { searchQuery } = this;
      await api.common.download({
        apiName: "/statistics/commodityTransactionListExportExcel",
        ...searchQuery,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
